import { render, staticRenderFns } from "./IncidentTemplateCreate.vue?vue&type=template&id=47951f3a&"
import script from "./IncidentTemplateCreate.vue?vue&type=script&lang=js&"
export * from "./IncidentTemplateCreate.vue?vue&type=script&lang=js&"
import style0 from "./IncidentTemplateCreate.vue?vue&type=style&index=0&id=47951f3a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCardSection,QStepper,QStep,QItem,QInput,QCardActions,QBtn});
