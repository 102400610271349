<template>
	<div>
		<q-card-section class="q-pa-none">
			<q-stepper
				v-model="step"
				ref="stepper"
				color="primary"
				flat
				animated
				:style="custom_style.background"
				:alternative-labels="$q.screen.gt.xs ? false : true"
			>
				<q-step
					:name="1"
					:done="step > 1"
					:title="'Choose ' + template_module_name + ' title'"
				/>
				<q-step
					:name="2"
					:done="step > 2"
					:title="'Create ' + template_module_name"
				/>
			</q-stepper>
		</q-card-section>
		<q-card-section v-if="!display_fields" class="q-pa-none">
			<q-item class="q-pa-none">
				<q-input
					class="full-width"
					outline
					v-model="title"
					label="Enter form title"
					clear-icon='close'
					:error="has_title_error"
					:error-message="title_error_message"
				/>
			</q-item>
			<q-card-actions align="right">
				<q-btn flat label="Cancel" color="primary" @click="$emit('cancel-incident')"/>
				<q-btn flat	label="Continue" color="primary" @click="startIncident"/>
			</q-card-actions>
		</q-card-section>
		<q-card-section v-else class="q-pa-none">
			<Incident
				:incident="incident"
				:is_template="true"
				:custom_style="custom_style"
				:template_module_name="template_module_name"
				:title_anonymous_user="title"
				@finish="$emit('finish')"
				@cancel-incident="$emit('cancel-incident')"
			/>
		</q-card-section>
	</div>
</template>

<script>
import Incident from "@/components/incidents/incidents/Incident.vue";

export default {
	name: "IncidentTemplateCreate",
	components: {
		Incident,
	},
	data () {
		return {
			title: '',
			selected_item_name: '',
			has_title_error: false,
			title_error_message: '',
			display_fields: false,
			step: 1,
		}
	},
	props: {
		incident: Object,
		is_template: Boolean,
		custom_style: Object,
		template_module_name: String,
	},
	methods: {
		startIncident(){
			this.has_title_error = false
			this.title_error_message = ''
			if(!this.title){
				this.has_title_error = true
				this.title_error_message = 'Please enter a form title'
				return
			}
			this.step += 1
			this.display_fields = true
		},
		executeLoader(){
			this.selected_item_name = this.incident.name
			this.title = this.selected_item_name
		},
	},
	created() {
		this.executeLoader()
	}
};
</script>
<style lang="scss">
	.q-stepper__step-inner {
		display: none;
	}
	.q-stepper__tab{
		padding: 0px;
	}
</style>