<template>
	<div v-if="this.template">
		<q-card
			:class="$q.screen.gt.xs ? 'q-ma-xl' : 'q-ma-sm'"
			style="min-height: 100px;"
			:style="custom_style.background"
			>
			<q-card-section  v-if="!is_done" class="q-pa-none">
				<q-card-section v-if="!create_incident">
					<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header" :style="custom_style.title">
						You are about to start a new {{ label }}
					</h1>
					<q-card-actions class="flex justify-center q-pa-none">
						<div class="full-width flex justify-center items-center">
							<vue-recaptcha
								class="q-mb-md"
								v-if="recaptcha"
								:sitekey="recaptcha"
								:size="$q.screen.lt.md ? 'compact' : 'normal'"
								@verify="verifyCaptcha"
							/>
						</div>
						<q-btn
							outline
							class="q-mb-md"
							style="width: 200px"
							color="primary"
							icon-right="o_cancel"
							label="Cancel"
							@click="exit"
						/>

						<q-btn
							class="q-mb-md"
							outline
							color="primary"
							style="width: 200px"
							icon-right="o_play_arrow"
							:label="'Start ' + label"
							:class="[recaptcha_verified ? 'confirm': 'disabled']"
							:disable="!recaptcha_verified"
							@click="verifyCreation"
						/>
					</q-card-actions>
				</q-card-section>
				<q-card-section v-else>
					<IncidentTemplateCreate
						:incident="new_incident"
						:is_template="true"
						:custom_style="custom_style"
						:template_module_name="template.module_name"
						@finish="exit"
						@cancel-incident="exitAnonymous"
					/>
				</q-card-section>
			</q-card-section>
			<q-card-section v-if="is_done">
				<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header" :style="custom_style.title">
					Thank you!
				</h1>
			</q-card-section>
			<q-card-section v-if="logo && !create_incident" class="items-center justify-center flex" >
				<q-img
					:src="logo"
					style="max-width:100px;"
				/>
			</q-card-section>
		</q-card>
	</div>
</template>

<script>
import Incident from "@/components/incidents/incidents/Incident.vue";
import IncidentTemplateAPI from "@/services/api/IncidentTemplate.js";
import IncidentTemplateCreate from "@/components/incidents/incidents/IncidentTemplateCreate.vue"
import VueRecaptcha from "vue-recaptcha";
import { mapActions, mapGetters } from "vuex";

export default {
	name: 'IncidentTemplates',
	components: {
		Incident,
		VueRecaptcha,
		IncidentTemplateCreate
	},
	data() {
		return {
			create_incident: false,
			is_done: false,
			recaptcha_verified: false,
			recaptcha: "",
			template: null,
			label: ""
		};
	},
	computed: {
		...mapGetters([
			'incident_template_subdomain',
			'new_incident',
			'template_errors',
			'incidents_errors',
		]),
		reject: () => "Cancel".toUpperCase(),
		confirm: function () {
			let title = this.template ? this.template.module_name : "...";
			return `Start ${title}`.toUpperCase();
		},
		logo: function () {
			try {
				return this.template.template_branding.logo;
			} catch (TypeError) {
				return null;
			}
		},
		custom_style: function () {
			let title = {};
			let background = {};
			let content = {};
			let label = {};

			if (!this.template || !this.template.template_branding) {
				return { title, background, content, label };
			}
			// Background
			if (this.template.template_branding.background_color) {
				background['background-color'] = this.template.template_branding.background_color;
			}
			// Title
			if (this.template.template_branding.title_size) {
				title['font-size'] = this.template.template_branding.title_size + 'px';
			}
			if (this.template.template_branding.title_color) {
				title['color'] = this.template.template_branding.title_color;
			}
			// Content
			if (this.template.template_branding.content_size) {
				content['font-size'] = this.template.template_branding.content_size + 'px';
			}
			if (this.template.template_branding.content_color) {
				content['color'] = this.template.template_branding.content_color;
			}
			// Labels
			if (this.template.template_branding.label_size) {
				label['font-size'] = this.template.template_branding.label_size + 'px';
			}
			if (this.template.template_branding.label_color) {
				label['color'] = this.template.template_branding.label_color;
			}
			return { title, background, content, label };
		},
	},
	methods: {
		...mapActions(["createIncidentFromTemplate"]),
		async verifyCreation() {
			if (this.recaptcha_verified) {
				await this.createIncidentFromTemplate(this.template.form);
				if (this.incidents_errors == "") {
					this.create_incident = true;
				}
			} else {
				this.$q.notify({
					timeout: 6700,
					message: 'An internal system error occurred. Please contact your administrator.',
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
		},
		exit() {
			if (!this.incidents_errors.length) {
				this.is_done = true;
			}
		},
		verifyCaptcha() {
			this.recaptcha_verified = true;
		},
		exitAnonymous() {
			this.create_incident = false;
			this.recaptcha_verified = false;
		}
	},
	async created() {
		this.recaptcha = await IncidentTemplateAPI.getRecaptchaKey();
		if (this.recaptcha.error) {
			this.recaptcha = "";
		}
		this.template = await IncidentTemplateAPI.retrieveIncidentTemplate(this.incident_template_subdomain);
		this.label = this.template.module_name
	}
};
</script>
